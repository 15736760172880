<template>
  <b-card
    class="align-items-center p-2 w-100 m-0 h-100 "
    no-body
  >
    <b-row class="text-center">
      <b-col>
        <b-avatar
          :src="friend.avatar_image"
          size="82"
        />
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <h5>{{ friend.name }}</h5>
      </b-col>
    </b-row>

    <b-row
      class="mt-1 mb-1"
    >
      <b-col
        class="d-flex justify-content-center mt-1"
        sm="12"
      >
        <b-button
          size="sm"
          variant="primary"
          class="flex-grow-1"
          @click="handleViewClick"
        >
          <feather-icon
            icon="EyeIcon"
            size="12"
            class="mr-1"
          />
          <span class="mr-25 align-middle">Profile</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- Unfriend -->
    <b-row
      class="mt-1"
    >
      <b-col
        class="d-flex justify-content-center"
        sm="12"
      >

        <b-button
          class="flex-grow-1"
          size="sm"
          variant="danger"
          @click="unfriend"
        >
          <b-spinner
            v-if="isSubmitting"
            label="Unfriending"
            small
          />

          <div v-else>
            <feather-icon
              icon="LogOutIcon"
              size="12"
            />
            <span class="mr-25 align-middle">Unfriend</span>
          </div>
        </b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BRow, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BSpinner,
  },
  props: {
    friend: {
      type: Object,
      default: null,
    },
    friendshipId: {
      type: Number,
      required: true,

    },
  },
  data() {
    return {
      currentTeam: null,
      isSubmitting: false,
    }
  },
  methods: {
    handleViewClick() {
      this.$router.push({
        name: 'player-profile',
        params: { id: this.friend.id },
      })
    },
    unfriend() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Leave',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isSubmitting = true

          const { success } = await this.$api.player.unfriend(this.friendshipId)

          this.isSubmitting = false

          if (success === true) {
            await this.$store.dispatch('user/fetchMe')
          }
        }
      })
    },
  },
}
</script>
