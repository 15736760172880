<template>
  <div>
    <div v-if="isUserLoading">
      <b-spinner
        label="Loading..."
        variant="primary"
      />
    </div>
    <div v-if="me">
      <b-tabs
        v-model="activeTab"
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        nav-class="nav-left"
        nav-wrapper-class="col-md-3 col-12"
        pills
        vertical
      >
        <!-- settings -->
        <b-tab @click="$router.push({name: 'account-settings', params: {tab: 'settings'}})">
          <template #title>
            <feather-icon
              class="mr-50"
              icon="SettingsIcon"
              size="18"
            />
            <span class="font-weight-bold">Settings</span>
          </template>

          <!-- Username & other account details -->
          <b-card no-body>
            <b-card-header><strong>Account details</strong></b-card-header>
            <b-card-body>
              <validation-observer ref="accountDetailsRef">
                <b-form
                  class="mt-2"
                  enctype="multipart/form-data"
                  @submit.stop.prevent="saveUser"
                >
                  <b-row>
                    <b-col
                      md="6"
                      sm="12"
                    >
                      <b-form-group
                        label="Username"
                        label-for="username"
                      >
                        <validation-provider
                          v-slot="{ errors: componentErrors }"
                          name="username"
                          rules="required"
                        >
                          <b-form-input
                            v-model="name"
                            name="username"
                            placeholder="Username"
                          />

                          <small class="text-danger">
                            {{ componentErrors[0] }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      md="6"
                      sm="12"
                    >
                      <b-form-group
                        label="E-mail"
                        label-for="account-e-mail"
                      >
                        <validation-provider
                          v-slot="{ errors: componentErrors }"
                          name="email"
                          rules="required"
                        >
                          <b-form-input
                            v-model="email"
                            name="email"
                            placeholder="Email"
                          />
                          <small class="text-danger">
                            {{ componentErrors[0] }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      md="6"
                      sm="12"
                    >
                      <b-form-group>
                        <label for="country">Country</label>
                        <v-select
                          v-model="country_id"
                          :options="countries"
                          :reduce="country => country.code"
                          name="country"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col sm="12">
                      <dismissible-message-box
                        :variant="updateProfileMessageType"
                        :show="updateProfileMessageShow"
                        class="p-1"
                      >
                        {{ updateProfileMessage }}
                      </dismissible-message-box>
                    </b-col>

                    <b-col md="12">
                      <spinning-button
                        :is-loading="loadingGeneral"
                        class="mt-1 mr-1"
                        type="submit"
                        variant="primary"
                      >
                        Update profile
                      </spinning-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>

          <!-- Profile picture -->
          <b-card no-body>
            <b-card-header><strong>Profile picture</strong></b-card-header>
            <b-card-body>
              <b-media
                class="d-flex align-items-center"
                no-body
              >
                <b-media-aside>
                  <b-avatar
                    :src="avatar_image"
                    button
                    size="100px"
                    square
                    variant="primary"
                    @click="openAvatarFilePopup"
                  />
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- upload button -->
                  <b-button
                    class="mb-75 mr-75"
                    size="sm"
                    variant="primary"
                    @click="openAvatarFilePopup"
                  >
                    Change
                  </b-button>

                  <b-form-file
                    v-show="false"
                    ref="avatarFileInput"
                    v-model="avatar"
                    class="mt-1"
                    plain
                  />

                  <!-- reset -->
                  <b-button
                    class="mb-75 mr-75"
                    size="sm"
                    variant="outline-secondary"
                    @click="removeAvatar"
                  >
                    Remove
                  </b-button>
                  <!--/ reset -->
                  <p><i>Allowed JPG, GIF or PNG. Max size of 800kB</i></p>
                </b-media-body>
              </b-media>

              <b-row>
                <b-col sm="12">
                  <dismissible-message-box
                    class="p-1"
                    :variant="updateAvatarMessageType"
                    :show="updateAvatarMessageShow"
                  >
                    {{ updateAvatarMessage }}
                  </dismissible-message-box>
                </b-col>

                <b-col md="12">
                  <spinning-button
                    :is-loading="loadingAvatar"
                    class="mt-1 mr-1"
                    type="submit"
                    variant="primary"
                    :disabled="!avatar_image"
                    @click.native="saveAvatar"
                  >
                    Update profile picture
                  </spinning-button>
                </b-col>
              </b-row>

            </b-card-body>
          </b-card>

          <!-- change password -->
          <b-card no-body>
            <b-card-header><strong>Change password</strong></b-card-header>
            <b-card-body>
              <validation-observer ref="passwordValidator">
                <b-form @submit.stop.prevent="changePassword">
                  <b-row>
                    <!-- old password -->
                    <b-col md="6">
                      <b-form-group
                        label="Old Password"
                        label-for="account-old-password"
                      >
                        <validation-provider
                          v-slot="{ errors: componentErrors }"
                          name="old password"
                          rules="required"
                        >
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="account-old-password"
                              v-model="passwordValueOld"
                              :type="passwordFieldTypeOld"
                              name="old-password"
                              placeholder="Old Password"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIconOld"
                                class="cursor-pointer"
                                @click="togglePasswordOld"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">
                            {{ componentErrors[0] }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  <!--/ old password -->
                  </b-row>
                  <b-row>
                    <!-- new password -->
                    <b-col md="6">
                      <b-form-group
                        label="New Password"
                        label-for="account-new-password"
                      >
                        <validation-provider
                          v-slot="{ errors: componentErrors }"
                          name="new-password"
                          rules="required|min:8"
                        >
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="account-new-password"
                              v-model="newPasswordValue"
                              :type="passwordFieldTypeNew"
                              name="new-password"
                              placeholder="New Password"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIconNew"
                                class="cursor-pointer"
                                @click="togglePasswordNew"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">
                            {{ componentErrors[0] }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--/ new password -->

                    <!-- retype password -->
                    <b-col md="6">
                      <b-form-group
                        label="Retype New Password"
                        label-for="account-retype-new-password"
                      >
                        <validation-provider
                          v-slot="{ errors: componentErrors }"
                          name="retype password"
                          rules="confirmed:new-password"
                        >
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="account-retype-new-password"
                              v-model="retypePassword"
                              :type="passwordFieldTypeRetype"
                              name="retype-password"
                              placeholder="New Password"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                :icon="passwordToggleIconRetype"
                                class="cursor-pointer"
                                @click="togglePasswordRetype"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">
                            {{ componentErrors[0] }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--/ retype password -->

                    <b-col sm="12">
                      <dismissible-message-box
                        :variant="updatePasswordMessageType"
                        :show="updatePasswordMessageShow"
                        class="p-1"
                      >
                        {{ updatePasswordMessage }}
                      </dismissible-message-box>
                    </b-col>

                    <!-- buttons -->
                    <b-col cols="12">
                      <spinning-button
                        :is-loading="loadingPassword"
                        class="mt-1 mr-1"
                        type="submit"
                        variant="primary"
                      >
                        Change password
                      </spinning-button>
                    </b-col>
                  <!--/ buttons -->
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>

          <b-card no-body>
            <b-card-header><strong>Social profiles</strong></b-card-header>
            <b-card-body>
              <validation-observer ref="socialLinksObserver">
                <b-form @submit.stop.prevent="updateSocialLinks">
                  <b-row>
                    <!-- twitter -->
                    <b-col md="6">
                      <b-form-group
                        label="Twitter"
                        label-for="account-twitter"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Twitter URL"
                          rules="url"
                        >
                          <b-form-input
                            id="account-twitter"
                            v-model="socialLinks.twitter.url"
                            placeholder="Add twitter link"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--/ twitter -->

                    <!-- facebook -->
                    <b-col md="6">
                      <b-form-group
                        label="Facebook"
                        label-for="account-facebook"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Facebook URL"
                          rules="url"
                        >
                          <b-form-input
                            id="account-facebook"
                            v-model="socialLinks.facebook.url"
                            placeholder="Add facebook link"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--/ facebook -->

                    <!-- instagram -->
                    <b-col md="6">
                      <b-form-group
                        label="Instagram"
                        label-for="account-instagram"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Instagram URL"
                          rules="url"
                        >
                          <b-form-input
                            id="account-instagram"
                            v-model="socialLinks.instagram.url"
                            placeholder="Add instagram link"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--/ instagram -->

                    <!-- discord -->
                    <b-col md="6">
                      <b-form-group
                        label="Discord"
                        label-for="account-discord"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Discord URL"
                          rules="url"
                        >
                          <b-form-input
                            id="account-discord"
                            v-model="socialLinks.discord.url"
                            placeholder="Add discord link"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- discord -->

                    <b-col sm="12">
                      <dismissible-message-box
                        key="updateLinkMessage"
                        :variant="updateLinkMessageType"
                        :show="updateLinkMessageShow"
                        class="p-1"
                      >
                        {{ updateLinkMessage }}
                      </dismissible-message-box>
                    </b-col>

                    <!-- buttons -->
                    <b-col cols="12">

                      <spinning-button
                        :is-loading="loadingSocialLinks"
                        class="mt-1 mr-1"
                        type="submit"
                        variant="primary"
                      >
                        Update social profiles
                      </spinning-button>
                    </b-col>
                  <!--/ buttons -->
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-tab>

        <!-- friends -->
        <b-tab
          lazy
          @click="$router.push({name: 'account-settings', params: {tab: 'friends'}})"
        >
          <template #title>
            <feather-icon
              class="mr-50"
              icon="UserIcon"
              size="18"
            />
            <span class="font-weight-bold">Friends</span>
          </template>

          <b-row>
            <b-card
              no-body
              class="p-2 w-100"
            >
              <h5>My friends</h5>
            </b-card>
          </b-row>

          <b-row>
            <b-col
              sm="12"
            >
              <!-- modal -->
              <div class="mb-1">
                <b-modal
                  id="profile-add-friends"
                  v-model="showAddFriends"
                  ok-title="Add"
                  title="Add friend"
                  @hidden="onHidden"
                  @ok.prevent="addFriends"
                >
                  <template #modal-footer="{ cancel, ok }">
                    <b-row class="w-100 mr-1 ml-1">
                      <b-col>
                        <dismissible-message-box
                          :show="friendMessageShow"
                          :variant="friendMessageType"
                          class="p-1"
                          :auto-dismiss="6000"
                        >
                          {{ friendMessage }}
                        </dismissible-message-box>
                      </b-col>
                    </b-row>
                    <b-row class="justify-content-between w-100 mr-1 ml-1">
                      <spinning-button
                        :is-loading="loadingAddFriends"
                        type="submit"
                        variant="primary"
                        @click.native="ok"
                      >
                        Add
                      </spinning-button>
                      <b-button
                        variant="outline-primary"
                        @click="cancel"
                      >
                        Close
                      </b-button>
                    </b-row>
                  </template>

                  <v-select
                    id="friends"
                    v-model="selectedFriendsOptions"
                    :filterable="false"
                    :options="userOptions"
                    :reduce="option => option.id"
                    multiple
                    name="friends"
                    @search="onUsersSearch"
                  >
                    <template #no-options>
                      type to search 360-clash users ...
                    </template>
                    <template #option="{ name, avatar_image }">
                      <div class="d-center">
                        <b-avatar
                          :src="avatar_image"
                          :variant="`primary`"
                          class="mr-2"
                          size="30"
                        />
                        {{ name }}
                      </div>
                    </template>
                    <template #selected-option="{ name, avatar_image }">
                      <div class="selected d-center">
                        <b-avatar
                          :src="avatar_image"
                          :variant="`primary`"
                          class="mr-2"
                          size="30"
                        />
                        {{ name }}
                      </div>
                    </template>
                  </v-select>
                </b-modal>
              </div>

              <!-- list friends -->
              <div>
                <b-row>
                  <b-col
                    sm="12"
                    lg="3"
                    md="6"
                    class="pl-0"
                  >
                    <account-settings-add-friend-card @addFriendButtonClicked="handleAddFriendClick" />
                  </b-col>

                  <b-col
                    v-for="friend in friendInvites"
                    :key="friend.id"
                    sm="12"
                    lg="3"
                    md="6"
                  >
                    <account-settings-friend-invitation-card
                      :friend="friend"
                      :friendship-id="friend.id"
                    />
                  </b-col>

                  <b-col
                    v-for="friend in friends"
                    :key="friend.id"
                    sm="12"
                    lg="3"
                    md="6"
                  >
                    <account-settings-friend-card
                      :friend="friend.friend"
                      :friendship-id="friend.id"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <!-- teams -->
        <b-tab
          lazy
          @click="$router.push({name: 'account-settings', params: {tab: 'teams'}})"
        >
          <template #title>
            <feather-icon
              class="mr-50"
              icon="UsersIcon"
              size="18"
            />
            <span class="font-weight-bold">Teams</span>
          </template>

          <b-row v-if="me?.team_invites?.length > 0">
            <b-card
              no-body
              class="p-2 w-100"
            >
              <h5>Team invites:</h5>
            </b-card>
          </b-row>

          <b-row
            v-if="me?.team_invites?.length > 0"
            class="flex-column"
          >
            <team-membership-invite-modal
              :invitation="currentTeamInvitation"
              @inviteStatusChanged="handleInviteStatusChanged"
            />

            <b-col
              v-for="invite in me?.team_invites"
              :key="invite.id"
              sm="12"
              md="6"
              lg="3"
              class="mb-1"
            >
              <account-settings-team-invite-card
                :invite="invite"
                @teamInviteRespondClick="openTeamInviteModal"
              />
            </b-col>
          </b-row>

          <!-- my teams -->
          <b-row>
            <b-card
              no-body
              class="p-2 w-100"
            >
              <h5 class="mb-0">
                My teams
              </h5>
            </b-card>
          </b-row>

          <b-row
            v-if="$store.getters['user/isLoading']"
            class="w-100"
          >
            <b-col>
              <b-spinner

                label="Loading..."
              />
            </b-col>
          </b-row>

          <b-row
            v-else
            class="w-100"
          >
            <b-col
              lg="3"
              sm="12"
              md="6"
              class="mb-1 pl-0"
            >
              <account-settings-add-team-card />
            </b-col>
            <b-col
              v-for="team in teams"
              :key="team.id"
              sm="12"
              md="6"
              lg="3"
              class="mb-1"
            >
              <account-settings-team-card
                :team="team"
                @edit-team-clicked="handleEditTeam"
              />
            </b-col>
          </b-row>

        </b-tab>

        <b-tab
          lazy
          @click="$router.push({name: 'account-settings', params: {tab: 'organizers'}})"
        >
          <template #title>
            <feather-icon
              class="mr-50"
              icon="AirplayIcon"
              size="18"
            />
            <span class="font-weight-bold">Organizers</span>
          </template>

          <organizer-invite-modal
            :invitation="currentOrganizerInvitation"
          />

          <b-row v-if="me?.organizer_invites?.length > 0">
            <b-card
              no-body
              class="p-2 w-100"
            >
              <h5>Organizer invites:</h5>
            </b-card>
          </b-row>

          <b-row
            v-if="me?.organizer_invites?.length > 0"
            class="flex-column"
          >
            <b-col
              v-for="invite in me?.organizer_invites"
              :key="invite.id"
              sm="12"
              md="6"
              lg="3"
              class="mb-1"
            >
              <b-card
                class="text-center p-2 w-100"
                no-body
              >
                <b-row class="text-center">
                  <b-col>
                    <b-avatar
                      :src="invite?.organizer?.avatar_image"
                      size="82"
                    />
                  </b-col>
                </b-row>

                <b-row class="mt-1">
                  <b-col>
                    <h5>{{ invite?.organizer?.name }}</h5>
                  </b-col>
                </b-row>

                <b-row class="mt-1">
                  <b-col>
                    <b-button
                      size="sm"
                      variant="primary"
                      @click="() => organizerInviteRespondClick(invite)"
                    >
                      Respond
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <!-- my teams -->
          <b-row>
            <b-card
              no-body
              class="p-2 w-100"
            >
              <h5>My organizers</h5>
            </b-card>
          </b-row>
          <b-row class="w-100">
            <b-col
              v-for="organizer in me?.organizers"
              :key="organizer.id"
              sm="12"
              md="6"
              lg="3"
            >
              <account-settings-organizer-card
                :organizer="organizer"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BSpinner,
  BTab,
  BTabs,
  VBModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

// eslint-disable-next-line import/no-extraneous-dependencies
import _debounce from 'lodash.debounce'
import LinkType from '@/constants/LinkType'
import LinkEntityType from '@/constants/LinkEntityType'
import { Countries } from '@/constants/Countries'
import SpinningButton from '@/components/SpinningButton.vue'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import useApi from '@/api/useApi'
import AccountSettingsTeamCard from '@/views/account/AccountSettingsTeamCard.vue'
import AccountSettingsTeamInviteCard from '@/views/account/AccountSettingsTeamInviteCard.vue'
import TeamMembershipInviteModal from '@/views/player/TeamMembershipInviteModal.vue'
import AccountSettingsOrganizerCard from '@/views/account/AccountSettingsOrganizerCard.vue'
import OrganizerInviteModal from '@/views/organizer/OrganizerInviteModal.vue'
import AccountSettingsAddTeamCard from '@/views/account/AccountSettingsAddTeamCard.vue'
import AccountSettingsFriendCard from '@/views/account/AccountSettingsFriendCard.vue'
import AccountSettingsAddFriendCard from '@/views/account/AccountSettingsAddFriendCard.vue'
import AccountSettingsFriendInvitationCard from '@/views/account/AccountSettingsFriendInvitationCard.vue'

export default {
  components: {
    AccountSettingsFriendInvitationCard,
    AccountSettingsAddFriendCard,
    AccountSettingsFriendCard,
    AccountSettingsAddTeamCard,
    OrganizerInviteModal,
    AccountSettingsOrganizerCard,
    TeamMembershipInviteModal,
    AccountSettingsTeamInviteCard,
    AccountSettingsTeamCard,
    DismissibleMessageBox,
    BTabs,
    BTab,
    BCard,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    SpinningButton,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
  },

  data() {
    return {
      updateProfileMessageType: MessageType.SUCCESS,
      updateProfileMessageShow: false,
      updateProfileMessage: null,
      updateAvatarMessageType: MessageType.SUCCESS,
      updateAvatarMessageShow: false,
      updateAvatarMessage: null,
      updatePasswordMessageType: MessageType.SUCCESS,
      updatePasswordMessageShow: false,
      updatePasswordMessage: null,
      updateLinkMessageType: MessageType.SUCCESS,
      updateLinkMessageShow: false,
      updateLinkMessage: null,
      friendMessageType: MessageType.SUCCESS,
      friendMessageShow: false,
      friendMessage: null,

      user: null,
      avatar: null,
      avatar_image: null,
      activeTab: this.getActiveTab(),
      email: this.me?.email,
      name: this.me?.name,
      country_id: this.me?.country_id,
      selectedFriendsOptions: [],
      userOptions: [],
      loadingPassword: false,
      loadingAddFriends: false,
      loadingAvatar: false,
      loadingSocialLinks: false,
      loadingGeneral: false,
      passwordValueOld: '',
      newPasswordValue: '',
      retypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      selectedFile: null,
      showCropDialog: false,
      mimeType: null,
      croppedImage: null,
      friends: [],
      teams: this.me?.teams || [],
      showAddFriends: false,
      socialLinks: {
        facebook: {
          id: null,
          url: null,
          link_type_id: LinkType.FACEBOOK,
        },
        twitter: {
          id: null,
          url: null,
          link_type_id: LinkType.TWITTER,
        },
        instagram: {
          id: null,
          url: null,
          link_type_id: LinkType.INSTAGRAM,
        },
        discord: {
          id: null,
          url: null,
          link_type_id: LinkType.DISCORD,
        },
      },
      selectedTeamToEdit: null,
      currentTeamInvitation: null,
      currentOrganizerInvitation: null,
    }
  },
  computed: {
    friendInvites() {
      return this.me?.friend_invites || []
    },
    countries() {
      return Countries.map(country => ({ code: country.id, label: country.name }))
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    me() {
      return this.$store.getters['user/getMe']
    },
    isUserLoading() {
      return this.$store.getters['user/isLoading']
    },
  },
  watch: {
    '$route.params.tab': function (newTab) {
      this.activeTab = this.getActiveTab(newTab)
    },
    avatar(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then(value => {
              this.avatar_image = value
            })
            .catch(() => {
              this.avatar_image = null
            })
        } else {
          this.avatar_image = null
        }
      }
    },
    me(newValue) {
      this.user = newValue
      this.email = this.user?.email
      this.name = this.user?.name
      this.country_id = this.user?.country_id
      this.avatar_image = this.user?.avatar_image

      this.friends = this.me?.friends || []
      this.teams = this.me?.teams || []
    },
  },
  created() {
    this.setActiveTab(this.$route.params?.tab)
  },
  async mounted() {
    this.email = this.me?.email
    this.name = this.me?.name
    this.country_id = this.me?.country_id
    this.avatar_image = this.me?.avatar_image
    this.socialLinks.twitter = this.me?.links?.find(
      link => link.link_type_id === LinkType.TWITTER,
    ) || this.socialLinks.twitter
    this.socialLinks.facebook = this.me?.links?.find(
      link => link.link_type_id === LinkType.FACEBOOK,
    ) || this.socialLinks.facebook
    this.socialLinks.instagram = this.me?.links?.find(
      link => link.link_type_id === LinkType.INSTAGRAM,
    ) || this.socialLinks.instagram
    this.socialLinks.discord = this.me?.links?.find(
      link => link.link_type_id === LinkType.DISCORD,
    ) || this.socialLinks.discord

    this.friends = this.me?.friends || []
    this.teams = this.me?.teams || []
  },
  methods: {
    handleAddFriendClick() {
      this.$bvModal.show('profile-add-friends')
    },
    setActiveTab(tabName) {
      this.activeTab = this.getActiveTab(tabName)
    },
    handleInviteStatusChanged() {
      this.$store.dispatch('user/fetchMe')
    },
    openTeamInviteModal(invitation) {
      this.currentTeamInvitation = invitation

      this.$bvModal.show('team-membership-invite-modal')
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(data)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    openAvatarFilePopup() {
      this.$refs.avatarFileInput.$el.click()
    },
    handleEditTeam(team) {
      this.selectedTeamToEdit = team || null

      this.$router.push({ name: 'team-edit', params: { id: team.id } })
    },
    organizerInviteRespondClick(invitation) {
      this.currentOrganizerInvitation = invitation

      this.$bvModal.show('organizer-invite-modal')
    },
    getActiveTab(tabName) {
      switch (tabName) {
        case 'friends':
          return 1
        case 'teams':
          return 2
        case 'organizers':
          return 3
        case 'settings':
        default:
          return 0
      }
    },

    async onUsersSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.searchUsers(loading, search, this)
      }
    },
    searchUsers: _debounce(async (loading, search, vm) => {
      const { data } = await useApi().player.fetchUsers(search)

      // eslint-disable-next-line no-param-reassign
      vm.userOptions = data

      loading(false)
    }, 350),
    async addFriends() {
      this.friendMessageShow = false
      if (this.selectedFriendsOptions?.length === 0) {
        return
      }

      this.loadingAddFriends = true

      const { errors } = await this.$api.player.addFriends(this.selectedFriendsOptions)

      this.loadingAddFriends = false

      if (errors) {
        this.friendMessage = 'There was a problem sending friend request.'
        this.friendMessageType = MessageType.ERROR
        this.friendMessageShow = true
        return
      }

      this.friendMessageShow = true
      this.friendMessageType = MessageType.SUCCESS
      this.friendMessage = 'Your friend received your friendship request but still needs to accept it to see him on your friends list.'

      await this.$store.dispatch('user/fetchMe')

      setTimeout(() => {
        this.$bvModal.hide('profile-add-friends')
      }, 4000)
    },
    async saveUser() {
      this.updateProfileMessageShow = false

      const formValid = await this.$refs.accountDetailsRef.validate()
      if (!formValid) {
        return
      }

      const payload = {
        name: this.name,
        email: this.email,
        country_id: this.country_id || null,
      }

      this.loadingGeneral = true

      const { errors } = await this.$api.player.saveUser(payload)

      this.loadingGeneral = false

      if (errors) {
        this.updateProfileMessage = 'There was a problem saving your account data.'

        if (errors[0]?.extensions.code === 'INVALID_NAME_OR_EMAIL') {
          this.updateProfileMessage = 'Unable to change username'
        }

        this.updateProfileMessageType = MessageType.ERROR
        this.updateProfileMessageShow = true

        return
      }

      await this.$store.dispatch('user/fetchMe')

      this.updateProfileMessage = 'You successfully updated your account data.'
      this.updateProfileMessageType = MessageType.SUCCESS
      this.updateProfileMessageShow = true
    },
    async saveAvatar() {
      this.updateAvatarMessageShow = false

      this.loadingAvatar = true

      const { errors } = await this.$api.player.updateUserAvatar(this.avatar)

      this.loadingAvatar = false

      if (errors) {
        this.updateAvatarMessage = 'There was a problem saving avatar.'

        this.updateAvatarMessageType = MessageType.ERROR
        this.updateAvatarMessageShow = true

        return
      }

      await this.$store.dispatch('user/fetchMe')

      this.updateAvatarMessage = 'You successfully updated your avatar.'
      this.updateAvatarMessageType = MessageType.SUCCESS
      this.updateAvatarMessageShow = true
    },
    async updateSocialLinks() {
      this.updateLinkMessageShow = false

      const formValid = await this.$refs.socialLinksObserver.validate()
      if (!formValid) {
        return
      }

      this.loadingSocialLinks = true

      const payload = []
      if (this.socialLinks.facebook?.id || this.socialLinks.facebook?.url) {
        payload.push({
          id: this.socialLinks.facebook.id || null,
          url: this.socialLinks.facebook.url,
          link_type_id: this.socialLinks.facebook.link_type_id,
          link_entity_id: this.me.id,
          link_entity_type: LinkEntityType.USER,
        })
      }

      if (this.socialLinks.twitter?.id || this.socialLinks.twitter?.url) {
        payload.push({
          id: this.socialLinks.twitter.id || null,
          url: this.socialLinks.twitter.url,
          link_type_id: this.socialLinks.twitter.link_type_id,
          link_entity_id: this.me.id,
          link_entity_type: LinkEntityType.USER,
        })
      }

      if (this.socialLinks.discord?.id || this.socialLinks.discord?.url) {
        payload.push({
          id: this.socialLinks.discord.id || null,
          url: this.socialLinks.discord.url,
          link_type_id: this.socialLinks.discord.link_type_id,
          link_entity_id: this.me.id,
          link_entity_type: LinkEntityType.USER,
        })
      }

      if (this.socialLinks.instagram?.id || this.socialLinks.instagram?.url) {
        payload.push({
          id: this.socialLinks.instagram.id || null,
          url: this.socialLinks.instagram.url,
          link_type_id: this.socialLinks.instagram.link_type_id,
          link_entity_id: this.me.id,
          link_entity_type: LinkEntityType.USER,
        })
      }

      const { data, errors } = await this.$api.player.updateSocialLinks(payload)

      this.loadingSocialLinks = false

      if (errors) {
        this.updateLinkMessage = 'There was a problem saving your social profiles data.'
        this.updateLinkMessageType = MessageType.ERROR
        this.updateLinkMessageShow = true

        return
      }

      if (data) {
        this.socialLinks.twitter = data.find(link => link.link_type_id === LinkType.TWITTER)
            || this.socialLinks.twitter
        this.socialLinks.facebook = data.find(link => link.link_type_id === LinkType.FACEBOOK)
            || this.socialLinks.facebook
        this.socialLinks.instagram = data.find(link => link.link_type_id === LinkType.INSTAGRAM)
            || this.socialLinks.instagram
        this.socialLinks.discord = data.find(link => link.link_type_id === LinkType.DISCORD)
            || this.socialLinks.discord
      }

      this.updateLinkMessage = 'You successfully saved your social profiles data.'
      this.updateLinkMessageType = MessageType.SUCCESS
      this.updateLinkMessageShow = true
    },
    async updateTeams() {
      this.loadingTeams = true

      this.teams = []

      // const { data } = await fetchMyTeams()
      //
      // this.teams = data

      this.loadingTeams = false
    },
    async removeAvatar() {
      this.updateAvatarMessageShow = false

      const { errors } = await this.$api.player.removeUserAvatar()

      if (errors) {
        this.updateAvatarMessage = 'There was a problem removing your avatar.'
        this.updateAvatarMessageType = MessageType.ERROR
        this.updateAvatarMessageShow = true
        return
      }

      this.updateAvatarMessage = 'You successfully removed your avatar.'
      this.updateAvatarMessageType = MessageType.SUCCESS
      this.updateAvatarMessageShow = true

      this.avatar = null
      this.avatar_image = null
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async changePassword() {
      const formValid = await this.$refs.passwordValidator.validate()
      if (!formValid) {
        return
      }

      this.loadingPassword = true
      this.updatePasswordMessageShow = false

      const { success, errors } = await this.$api.player.changePassword(
        this.passwordValueOld,
        this.newPasswordValue,
        this.retypePassword,
      )

      this.loadingPassword = false

      if (errors?.length > 0 || !success) {
        this.updatePasswordMessage = 'There was a problem changing your password. Make sure your password is different than previous one. If the problem persists, please contact our support.'

        if (
          errors.find(e => e.extensions.code === 'INVALID_CURRENT_PASSWORD')
        ) {
          this.updatePasswordMessage = 'You current password is invalid.'
        }

        if (
          errors.find(e => e.extensions.code === 'PASSWORDS_DOES_NOT_MATCH')
        ) {
          this.updatePasswordMessage = 'New passwords does not match.'
        }

        this.updatePasswordMessageType = MessageType.ERROR
        this.updatePasswordMessageShow = true

        return
      }

      this.updatePasswordMessageType = MessageType.SUCCESS
      this.updatePasswordMessageShow = true
      this.updatePasswordMessage = 'You successfully changed your password.'
    },
    onHidden() {
      this.selectedFriendsOptions = null
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .dark-layout {
    .list-group {
      .list-group-item:not([class*='list-group-item-']):active {
        background-color: #283046;
      }

      .list-group-item:not([class*='list-group-item-']):hover {
        background-color: #283046;
      }
    }
  }
</style>
