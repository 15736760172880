var render = function () {
  var _vm$invite, _vm$invite$team, _vm$invite2, _vm$invite2$team;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "text-center p-2 w-100 h-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "text-center"
  }, [_c('b-col', [_c('b-avatar', {
    attrs: {
      "src": (_vm$invite = _vm.invite) === null || _vm$invite === void 0 ? void 0 : (_vm$invite$team = _vm$invite.team) === null || _vm$invite$team === void 0 ? void 0 : _vm$invite$team.avatar_image,
      "size": "82"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_c('h5', [_vm._v(_vm._s((_vm$invite2 = _vm.invite) === null || _vm$invite2 === void 0 ? void 0 : (_vm$invite2$team = _vm$invite2.team) === null || _vm$invite2$team === void 0 ? void 0 : _vm$invite2$team.name))])])], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function click() {
        return _vm.handleInvitationRespondClick(_vm.invite);
      }
    }
  }, [_vm._v(" Respond ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }