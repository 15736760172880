var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "justify-content-center align-items-center  p-2 w-100  h-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('b-link', {
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.handleCreateTeamClick
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusCircleIcon",
      "size": "50"
    }
  })], 1), _c('div', {
    staticClass: "text-center mt-1"
  }, [_vm._v(" Add team ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }