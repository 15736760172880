<template>
  <b-modal
    id="organizer-invite-modal"
    :title="title"
    no-close-on-backdrop
    @hidden="handleHide"
  >
    <template #modal-footer="{ cancel }">
      <b-row class="w-100 mr-1 ml-1 justify-content-start">
        <dismissible-message-box
          :auto-dismiss="6000"
          :show="showMessage"
          :variant="messageVariant"
          class="p-1"
        >
          {{ message }}
        </dismissible-message-box>
      </b-row>
      <b-button
        variant="outline-primary"
        @click="cancel"
      >
        Close
      </b-button>
    </template>

    <div class="d-flex flex-column align-items-center">
      <b-avatar
        :src="invitationLocal?.organizer?.avatar_image"
        :variant="`light-primary`"
        class="mb-2"
        size="104px"
      />
      <h4 class="mb-2">
        {{ invitationLocal?.organizer?.name }}
      </h4>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mb-2"
        size="sm"
        variant="primary"
        @click="handleViewProfileClick"
      >
        View organizer profile
      </b-button>

      <div class="d-flex justify-content-around w-50">
        <spinning-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-2"
          variant="success"
          :is-loading="loadingAccept"
          @click.native="() => handleAcceptClick(true)"
        >
          Accept
        </spinning-button>

        <spinning-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-2"
          variant="danger"
          :is-loading="loadingDecline"
          @click.native="() => handleAcceptClick(false)"
        >
          Decline
        </spinning-button>

      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BAvatar, BButton, BModal, BRow, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import SpinningButton from '@/components/SpinningButton.vue'

export default {
  components: {
    SpinningButton,
    DismissibleMessageBox,
    BRow,
    BModal,
    BButton,
    BAvatar,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    invitation: {
      type: Object,
      default: () => ({
        organizer: {
          name: null,
          id: null,
        },
      }),
    },
  },
  data() {
    return {
      showMessage: false,
      message: null,
      messageVariant: MessageType.SUCCESS,
      errors: [],
      MessageType,
      invitationLocal: this.invitation,
      loadingDecline: false,
      loadingAccept: false,
    }
  },
  computed: {
    title() {
      return `Organizer ${this.invitationLocal?.organizer?.name} has invited you to join!`
    },
  },
  watch: {
    invitation(data) {
      this.invitationLocal = data
    },
  },
  methods: {
    handleViewProfileClick() {
      this.$router.push({
        name: 'organizer-profile',
        params: { id: this.invitationLocal?.organizer?.id },
      })
    },

    async handleAcceptClick(accept) {
      this.errors = []
      this.message = null
      this.messageVariant = MessageType.SUCCESS
      this.showMessage = false

      if (accept === true) {
        this.loadingAccept = true
      } else {
        this.loadingDecline = true
      }

      const { errors } = await this.$api.organizer.updateOrganizerInvite(
        this.invitationLocal.token,
        accept,
      )

      if (errors && errors.length > 0) {
        this.loadingAccept = false
        this.loadingDecline = false

        this.message = 'There was an error saving invitation response data. Please try again or contact our support'

        this.messageVariant = MessageType.ERROR

        this.showMessage = true

        return
      }

      if (accept === true) {
        this.message = `SUCCESS! You are now part of the ${this.invitation.organizer.name} organizer team!`
      } else {
        this.message = `Organizer ${this.invitation.organizer.name} was notified you declined the request to join the organization team!`
      }

      this.messageVariant = MessageType.SUCCESS

      this.showMessage = true

      setTimeout(() => {
        this.$bvModal.hide('organizer-invite-modal')
      }, 3000)

      await this.$store.dispatch('user/fetchMe')
    },

    handleHide() {
      this.errors = []
      this.showSuccessNotifications = false
      this.showErrorNotifications = false
      this.loadingDecline = false
      this.loadingAccept = false
    },
  },
}
</script>

<style lang="scss">
.modal-xl {
  margin-left: 17%;
  margin-right: 17%;
}

.modal .modal-header .close {
  transform: translate(-4px, 5px);

  &:hover,
  &:focus,
  &:active {
    transform: translate(-7px, 6px);
  }
}
</style>
