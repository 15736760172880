var render = function () {
  var _vm$invitationLocal, _vm$invitationLocal$o, _vm$invitationLocal2, _vm$invitationLocal2$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "organizer-invite-modal",
      "title": _vm.title,
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.handleHide
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-row', {
          staticClass: "w-100 mr-1 ml-1 justify-content-start"
        }, [_c('dismissible-message-box', {
          staticClass: "p-1",
          attrs: {
            "auto-dismiss": 6000,
            "show": _vm.showMessage,
            "variant": _vm.messageVariant
          }
        }, [_vm._v(" " + _vm._s(_vm.message) + " ")])], 1), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])];
      }
    }])
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "src": (_vm$invitationLocal = _vm.invitationLocal) === null || _vm$invitationLocal === void 0 ? void 0 : (_vm$invitationLocal$o = _vm$invitationLocal.organizer) === null || _vm$invitationLocal$o === void 0 ? void 0 : _vm$invitationLocal$o.avatar_image,
      "variant": "light-primary",
      "size": "104px"
    }
  }), _c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s((_vm$invitationLocal2 = _vm.invitationLocal) === null || _vm$invitationLocal2 === void 0 ? void 0 : (_vm$invitationLocal2$ = _vm$invitationLocal2.organizer) === null || _vm$invitationLocal2$ === void 0 ? void 0 : _vm$invitationLocal2$.name) + " ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-2",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleViewProfileClick
    }
  }, [_vm._v(" View organizer profile ")]), _c('div', {
    staticClass: "d-flex justify-content-around w-50"
  }, [_c('spinning-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-2",
    attrs: {
      "variant": "success",
      "is-loading": _vm.loadingAccept
    },
    nativeOn: {
      "click": function click($event) {
        return function () {
          return _vm.handleAcceptClick(true);
        }($event);
      }
    }
  }, [_vm._v(" Accept ")]), _c('spinning-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-2",
    attrs: {
      "variant": "danger",
      "is-loading": _vm.loadingDecline
    },
    nativeOn: {
      "click": function click($event) {
        return function () {
          return _vm.handleAcceptClick(false);
        }($event);
      }
    }
  }, [_vm._v(" Decline ")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }