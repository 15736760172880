<template>
  <b-card
    class="text-center p-2 w-100 h-100"
    no-body
  >
    <b-row class="text-center">
      <b-col>
        <b-avatar
          :src="invite?.team?.avatar_image"
          size="82"
        />
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <h5>{{ invite?.team?.name }}</h5>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <b-button
          size="sm"
          variant="primary"
          @click="() => handleInvitationRespondClick(invite)"
        >
          Respond
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    invite: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentTeam: null,
    }
  },
  methods: {
    handleInvitationRespondClick(invitation) {
      this.$emit('teamInviteRespondClick', invitation)
    },
  },
}
</script>
