<template>
  <b-card
    class="justify-content-center align-items-center p-2 w-100  h-100"
    no-body
  >

    <div class="text-center">
      <b-link
        size="sm"
        variant="outline-primary"
        @click="handleAddFriendClick"
      >
        <feather-icon
          icon="PlusCircleIcon"
          size="50"
        />
      </b-link>
      <div class="text-center mt-1">
        Add friend
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BCard,
  },
  props: {
    team: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentTeam: null,
    }
  },
  methods: {
    handleAddFriendClick() {
      this.$emit('addFriendButtonClicked')
    },
  },
}
</script>

<style scoped>
</style>
