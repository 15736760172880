var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "text-center p-2 w-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "text-center"
  }, [_c('b-col', [_c('b-avatar', {
    attrs: {
      "src": _vm.organizer.avatar_image,
      "size": "82"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_c('h5', [_vm._v(_vm._s(_vm.organizer.name))])])], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "sm": "12"
    }
  }, [_c('b-button', {
    staticClass: "flex-grow-1",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function click() {
        return _vm.viewOrganizer(_vm.organizer.id);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "RefreshCwIcon",
      "size": "12"
    }
  }), _c('span', {}, [_vm._v("Open Dash")])], 1)], 1)], 1), _vm.isOwner(_vm.organizer) ? _c('b-row', {
    staticClass: "mt-1 mb-1"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "sm": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'team-edit',
          params: {
            id: _vm.organizer.id
          }
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "EditIcon",
      "size": "12"
    }
  }), _c('span', {
    staticClass: "mr-25 align-middle"
  }, [_vm._v("Edit")])], 1)], 1), _c('b-col', {
    staticClass: "d-flex justify-content-center mt-1",
    attrs: {
      "sm": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleViewClick
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "EyeIcon",
      "size": "12"
    }
  }), _c('span', {
    staticClass: "mr-25 align-middle"
  }, [_vm._v("Visit")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }