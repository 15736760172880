var render = function () {
  var _vm$me, _vm$me$team_invites, _vm$me2, _vm$me2$team_invites, _vm$me3, _vm$me4, _vm$me4$organizer_inv, _vm$me5, _vm$me5$organizer_inv, _vm$me6, _vm$me7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isUserLoading ? _c('div', [_c('b-spinner', {
    attrs: {
      "label": "Loading...",
      "variant": "primary"
    }
  })], 1) : _vm._e(), _vm.me ? _c('div', [_c('b-tabs', {
    attrs: {
      "content-class": "col-12 col-md-9 mt-1 mt-md-0",
      "nav-class": "nav-left",
      "nav-wrapper-class": "col-md-3 col-12",
      "pills": "",
      "vertical": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('b-tab', {
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'account-settings',
          params: {
            tab: 'settings'
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "SettingsIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Settings")])];
      },
      proxy: true
    }], null, false, 3961467945)
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('strong', [_vm._v("Account details")])]), _c('b-card-body', [_c('validation-observer', {
    ref: "accountDetailsRef"
  }, [_c('b-form', {
    staticClass: "mt-2",
    attrs: {
      "enctype": "multipart/form-data"
    },
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.saveUser($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Username",
      "label-for": "username"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "username",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var componentErrors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "name": "username",
            "placeholder": "Username"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 1907640149)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "E-mail",
      "label-for": "account-e-mail"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "email",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var componentErrors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "name": "email",
            "placeholder": "Email"
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 2100683326)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "country"
    }
  }, [_vm._v("Country")]), _c('v-select', {
    attrs: {
      "options": _vm.countries,
      "reduce": function reduce(country) {
        return country.code;
      },
      "name": "country"
    },
    model: {
      value: _vm.country_id,
      callback: function callback($$v) {
        _vm.country_id = $$v;
      },
      expression: "country_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('dismissible-message-box', {
    staticClass: "p-1",
    attrs: {
      "variant": _vm.updateProfileMessageType,
      "show": _vm.updateProfileMessageShow
    }
  }, [_vm._v(" " + _vm._s(_vm.updateProfileMessage) + " ")])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('spinning-button', {
    staticClass: "mt-1 mr-1",
    attrs: {
      "is-loading": _vm.loadingGeneral,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Update profile ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('strong', [_vm._v("Profile picture")])]), _c('b-card-body', [_c('b-media', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', [_c('b-avatar', {
    attrs: {
      "src": _vm.avatar_image,
      "button": "",
      "size": "100px",
      "square": "",
      "variant": "primary"
    },
    on: {
      "click": _vm.openAvatarFilePopup
    }
  })], 1), _c('b-media-body', {
    staticClass: "mt-75 ml-75"
  }, [_c('b-button', {
    staticClass: "mb-75 mr-75",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.openAvatarFilePopup
    }
  }, [_vm._v(" Change ")]), _c('b-form-file', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "avatarFileInput",
    staticClass: "mt-1",
    attrs: {
      "plain": ""
    },
    model: {
      value: _vm.avatar,
      callback: function callback($$v) {
        _vm.avatar = $$v;
      },
      expression: "avatar"
    }
  }), _c('b-button', {
    staticClass: "mb-75 mr-75",
    attrs: {
      "size": "sm",
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.removeAvatar
    }
  }, [_vm._v(" Remove ")]), _c('p', [_c('i', [_vm._v("Allowed JPG, GIF or PNG. Max size of 800kB")])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('dismissible-message-box', {
    staticClass: "p-1",
    attrs: {
      "variant": _vm.updateAvatarMessageType,
      "show": _vm.updateAvatarMessageShow
    }
  }, [_vm._v(" " + _vm._s(_vm.updateAvatarMessage) + " ")])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('spinning-button', {
    staticClass: "mt-1 mr-1",
    attrs: {
      "is-loading": _vm.loadingAvatar,
      "type": "submit",
      "variant": "primary",
      "disabled": !_vm.avatar_image
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.saveAvatar($event);
      }
    }
  }, [_vm._v(" Update profile picture ")])], 1)], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('strong', [_vm._v("Change password")])]), _c('b-card-body', [_c('validation-observer', {
    ref: "passwordValidator"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.changePassword($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Old Password",
      "label-for": "account-old-password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "old password",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var componentErrors = _ref3.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge"
        }, [_c('b-form-input', {
          attrs: {
            "id": "account-old-password",
            "type": _vm.passwordFieldTypeOld,
            "name": "old-password",
            "placeholder": "Old Password"
          },
          model: {
            value: _vm.passwordValueOld,
            callback: function callback($$v) {
              _vm.passwordValueOld = $$v;
            },
            expression: "passwordValueOld"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIconOld
          },
          on: {
            "click": _vm.togglePasswordOld
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 667034715)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "New Password",
      "label-for": "account-new-password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "new-password",
      "rules": "required|min:8"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var componentErrors = _ref4.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge"
        }, [_c('b-form-input', {
          attrs: {
            "id": "account-new-password",
            "type": _vm.passwordFieldTypeNew,
            "name": "new-password",
            "placeholder": "New Password"
          },
          model: {
            value: _vm.newPasswordValue,
            callback: function callback($$v) {
              _vm.newPasswordValue = $$v;
            },
            expression: "newPasswordValue"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIconNew
          },
          on: {
            "click": _vm.togglePasswordNew
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 2965694464)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Retype New Password",
      "label-for": "account-retype-new-password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "retype password",
      "rules": "confirmed:new-password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var componentErrors = _ref5.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge"
        }, [_c('b-form-input', {
          attrs: {
            "id": "account-retype-new-password",
            "type": _vm.passwordFieldTypeRetype,
            "name": "retype-password",
            "placeholder": "New Password"
          },
          model: {
            value: _vm.retypePassword,
            callback: function callback($$v) {
              _vm.retypePassword = $$v;
            },
            expression: "retypePassword"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIconRetype
          },
          on: {
            "click": _vm.togglePasswordRetype
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(componentErrors[0]) + " ")])];
      }
    }], null, false, 1928097050)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('dismissible-message-box', {
    staticClass: "p-1",
    attrs: {
      "variant": _vm.updatePasswordMessageType,
      "show": _vm.updatePasswordMessageShow
    }
  }, [_vm._v(" " + _vm._s(_vm.updatePasswordMessage) + " ")])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('spinning-button', {
    staticClass: "mt-1 mr-1",
    attrs: {
      "is-loading": _vm.loadingPassword,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Change password ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('strong', [_vm._v("Social profiles")])]), _c('b-card-body', [_c('validation-observer', {
    ref: "socialLinksObserver"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.updateSocialLinks($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Twitter",
      "label-for": "account-twitter"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Twitter URL",
      "rules": "url"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "account-twitter",
            "placeholder": "Add twitter link"
          },
          model: {
            value: _vm.socialLinks.twitter.url,
            callback: function callback($$v) {
              _vm.$set(_vm.socialLinks.twitter, "url", $$v);
            },
            expression: "socialLinks.twitter.url"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3731515438)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Facebook",
      "label-for": "account-facebook"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Facebook URL",
      "rules": "url"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "account-facebook",
            "placeholder": "Add facebook link"
          },
          model: {
            value: _vm.socialLinks.facebook.url,
            callback: function callback($$v) {
              _vm.$set(_vm.socialLinks.facebook, "url", $$v);
            },
            expression: "socialLinks.facebook.url"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3975260379)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Instagram",
      "label-for": "account-instagram"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Instagram URL",
      "rules": "url"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "account-instagram",
            "placeholder": "Add instagram link"
          },
          model: {
            value: _vm.socialLinks.instagram.url,
            callback: function callback($$v) {
              _vm.$set(_vm.socialLinks.instagram, "url", $$v);
            },
            expression: "socialLinks.instagram.url"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 372707755)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Discord",
      "label-for": "account-discord"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Discord URL",
      "rules": "url"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "account-discord",
            "placeholder": "Add discord link"
          },
          model: {
            value: _vm.socialLinks.discord.url,
            callback: function callback($$v) {
              _vm.$set(_vm.socialLinks.discord, "url", $$v);
            },
            expression: "socialLinks.discord.url"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3803681399)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('dismissible-message-box', {
    key: "updateLinkMessage",
    staticClass: "p-1",
    attrs: {
      "variant": _vm.updateLinkMessageType,
      "show": _vm.updateLinkMessageShow
    }
  }, [_vm._v(" " + _vm._s(_vm.updateLinkMessage) + " ")])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('spinning-button', {
    staticClass: "mt-1 mr-1",
    attrs: {
      "is-loading": _vm.loadingSocialLinks,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Update social profiles ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'account-settings',
          params: {
            tab: 'friends'
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Friends")])];
      },
      proxy: true
    }], null, false, 1473952889)
  }, [_c('b-row', [_c('b-card', {
    staticClass: "p-2 w-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('h5', [_vm._v("My friends")])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_c('b-modal', {
    attrs: {
      "id": "profile-add-friends",
      "ok-title": "Add",
      "title": "Add friend"
    },
    on: {
      "hidden": _vm.onHidden,
      "ok": function ok($event) {
        $event.preventDefault();
        return _vm.addFriends($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref10) {
        var cancel = _ref10.cancel,
          ok = _ref10.ok;
        return [_c('b-row', {
          staticClass: "w-100 mr-1 ml-1"
        }, [_c('b-col', [_c('dismissible-message-box', {
          staticClass: "p-1",
          attrs: {
            "show": _vm.friendMessageShow,
            "variant": _vm.friendMessageType,
            "auto-dismiss": 6000
          }
        }, [_vm._v(" " + _vm._s(_vm.friendMessage) + " ")])], 1)], 1), _c('b-row', {
          staticClass: "justify-content-between w-100 mr-1 ml-1"
        }, [_c('spinning-button', {
          attrs: {
            "is-loading": _vm.loadingAddFriends,
            "type": "submit",
            "variant": "primary"
          },
          nativeOn: {
            "click": function click($event) {
              return ok($event);
            }
          }
        }, [_vm._v(" Add ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)];
      }
    }], null, false, 3151626658),
    model: {
      value: _vm.showAddFriends,
      callback: function callback($$v) {
        _vm.showAddFriends = $$v;
      },
      expression: "showAddFriends"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "friends",
      "filterable": false,
      "options": _vm.userOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "multiple": "",
      "name": "friends"
    },
    on: {
      "search": _vm.onUsersSearch
    },
    scopedSlots: _vm._u([{
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" type to search 360-clash users ... ")];
      },
      proxy: true
    }, {
      key: "option",
      fn: function fn(_ref11) {
        var name = _ref11.name,
          avatar_image = _ref11.avatar_image;
        return [_c('div', {
          staticClass: "d-center"
        }, [_c('b-avatar', {
          staticClass: "mr-2",
          attrs: {
            "src": avatar_image,
            "variant": "primary",
            "size": "30"
          }
        }), _vm._v(" " + _vm._s(name) + " ")], 1)];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref12) {
        var name = _ref12.name,
          avatar_image = _ref12.avatar_image;
        return [_c('div', {
          staticClass: "selected d-center"
        }, [_c('b-avatar', {
          staticClass: "mr-2",
          attrs: {
            "src": avatar_image,
            "variant": "primary",
            "size": "30"
          }
        }), _vm._v(" " + _vm._s(name) + " ")], 1)];
      }
    }], null, false, 2519753472),
    model: {
      value: _vm.selectedFriendsOptions,
      callback: function callback($$v) {
        _vm.selectedFriendsOptions = $$v;
      },
      expression: "selectedFriendsOptions"
    }
  })], 1)], 1), _c('div', [_c('b-row', [_c('b-col', {
    staticClass: "pl-0",
    attrs: {
      "sm": "12",
      "lg": "3",
      "md": "6"
    }
  }, [_c('account-settings-add-friend-card', {
    on: {
      "addFriendButtonClicked": _vm.handleAddFriendClick
    }
  })], 1), _vm._l(_vm.friendInvites, function (friend) {
    return _c('b-col', {
      key: friend.id,
      attrs: {
        "sm": "12",
        "lg": "3",
        "md": "6"
      }
    }, [_c('account-settings-friend-invitation-card', {
      attrs: {
        "friend": friend,
        "friendship-id": friend.id
      }
    })], 1);
  }), _vm._l(_vm.friends, function (friend) {
    return _c('b-col', {
      key: friend.id,
      attrs: {
        "sm": "12",
        "lg": "3",
        "md": "6"
      }
    }, [_c('account-settings-friend-card', {
      attrs: {
        "friend": friend.friend,
        "friendship-id": friend.id
      }
    })], 1);
  })], 2)], 1)])], 1)], 1), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'account-settings',
          params: {
            tab: 'teams'
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UsersIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Teams")])];
      },
      proxy: true
    }], null, false, 1852226661)
  }, [((_vm$me = _vm.me) === null || _vm$me === void 0 ? void 0 : (_vm$me$team_invites = _vm$me.team_invites) === null || _vm$me$team_invites === void 0 ? void 0 : _vm$me$team_invites.length) > 0 ? _c('b-row', [_c('b-card', {
    staticClass: "p-2 w-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('h5', [_vm._v("Team invites:")])])], 1) : _vm._e(), ((_vm$me2 = _vm.me) === null || _vm$me2 === void 0 ? void 0 : (_vm$me2$team_invites = _vm$me2.team_invites) === null || _vm$me2$team_invites === void 0 ? void 0 : _vm$me2$team_invites.length) > 0 ? _c('b-row', {
    staticClass: "flex-column"
  }, [_c('team-membership-invite-modal', {
    attrs: {
      "invitation": _vm.currentTeamInvitation
    },
    on: {
      "inviteStatusChanged": _vm.handleInviteStatusChanged
    }
  }), _vm._l((_vm$me3 = _vm.me) === null || _vm$me3 === void 0 ? void 0 : _vm$me3.team_invites, function (invite) {
    return _c('b-col', {
      key: invite.id,
      staticClass: "mb-1",
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "3"
      }
    }, [_c('account-settings-team-invite-card', {
      attrs: {
        "invite": invite
      },
      on: {
        "teamInviteRespondClick": _vm.openTeamInviteModal
      }
    })], 1);
  })], 2) : _vm._e(), _c('b-row', [_c('b-card', {
    staticClass: "p-2 w-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" My teams ")])])], 1), _vm.$store.getters['user/isLoading'] ? _c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 1) : _c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', {
    staticClass: "mb-1 pl-0",
    attrs: {
      "lg": "3",
      "sm": "12",
      "md": "6"
    }
  }, [_c('account-settings-add-team-card')], 1), _vm._l(_vm.teams, function (team) {
    return _c('b-col', {
      key: team.id,
      staticClass: "mb-1",
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "3"
      }
    }, [_c('account-settings-team-card', {
      attrs: {
        "team": team
      },
      on: {
        "edit-team-clicked": _vm.handleEditTeam
      }
    })], 1);
  })], 2)], 1), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'account-settings',
          params: {
            tab: 'organizers'
          }
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "AirplayIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Organizers")])];
      },
      proxy: true
    }], null, false, 211185813)
  }, [_c('organizer-invite-modal', {
    attrs: {
      "invitation": _vm.currentOrganizerInvitation
    }
  }), ((_vm$me4 = _vm.me) === null || _vm$me4 === void 0 ? void 0 : (_vm$me4$organizer_inv = _vm$me4.organizer_invites) === null || _vm$me4$organizer_inv === void 0 ? void 0 : _vm$me4$organizer_inv.length) > 0 ? _c('b-row', [_c('b-card', {
    staticClass: "p-2 w-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('h5', [_vm._v("Organizer invites:")])])], 1) : _vm._e(), ((_vm$me5 = _vm.me) === null || _vm$me5 === void 0 ? void 0 : (_vm$me5$organizer_inv = _vm$me5.organizer_invites) === null || _vm$me5$organizer_inv === void 0 ? void 0 : _vm$me5$organizer_inv.length) > 0 ? _c('b-row', {
    staticClass: "flex-column"
  }, _vm._l((_vm$me6 = _vm.me) === null || _vm$me6 === void 0 ? void 0 : _vm$me6.organizer_invites, function (invite) {
    var _invite$organizer, _invite$organizer2;
    return _c('b-col', {
      key: invite.id,
      staticClass: "mb-1",
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "3"
      }
    }, [_c('b-card', {
      staticClass: "text-center p-2 w-100",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-row', {
      staticClass: "text-center"
    }, [_c('b-col', [_c('b-avatar', {
      attrs: {
        "src": invite === null || invite === void 0 ? void 0 : (_invite$organizer = invite.organizer) === null || _invite$organizer === void 0 ? void 0 : _invite$organizer.avatar_image,
        "size": "82"
      }
    })], 1)], 1), _c('b-row', {
      staticClass: "mt-1"
    }, [_c('b-col', [_c('h5', [_vm._v(_vm._s(invite === null || invite === void 0 ? void 0 : (_invite$organizer2 = invite.organizer) === null || _invite$organizer2 === void 0 ? void 0 : _invite$organizer2.name))])])], 1), _c('b-row', {
      staticClass: "mt-1"
    }, [_c('b-col', [_c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "primary"
      },
      on: {
        "click": function click() {
          return _vm.organizerInviteRespondClick(invite);
        }
      }
    }, [_vm._v(" Respond ")])], 1)], 1)], 1)], 1);
  }), 1) : _vm._e(), _c('b-row', [_c('b-card', {
    staticClass: "p-2 w-100",
    attrs: {
      "no-body": ""
    }
  }, [_c('h5', [_vm._v("My organizers")])])], 1), _c('b-row', {
    staticClass: "w-100"
  }, _vm._l((_vm$me7 = _vm.me) === null || _vm$me7 === void 0 ? void 0 : _vm$me7.organizers, function (organizer) {
    return _c('b-col', {
      key: organizer.id,
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "3"
      }
    }, [_c('account-settings-organizer-card', {
      attrs: {
        "organizer": organizer
      }
    })], 1);
  }), 1)], 1)], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }