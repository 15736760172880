<template>
  <b-card
    class="align-items-center p-2 w-100 m-0 h-100 "
    no-body
  >
    <b-row class="text-center">
      <b-col>
        <b-avatar
          :src="friend?.user?.avatar_image"
          size="82"
        />
      </b-col>
    </b-row>

    <b-row class="mt-1  justify-content-between">
      <b-col class="text-center">
        <span class="font-weight-bolder">{{ friend?.user?.name }}</span> want's to become your friend?
      </b-col>
    </b-row>

    <b-row
      class="mt-1 mb-1  justify-content-between"
    >
      <b-col
        class="d-flex justify-content-center mt-1"
        sm="12"
      >
        <b-button
          size="sm"
          variant="primary"
          class="flex-grow-1"
          @click="handleViewClick"
        >
          <feather-icon
            icon="ViewIcon"
            size="16"
            class="mr-1"
          />
          <span>profile</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row
      class="mt-1 justify-content-between"
    >
      <b-col
        class="d-flex justify-content-center"
        sm="5"
      >

        <b-button
          size="sm"
          variant="danger"
          @click="handleDeclineClick"
        >
          <b-spinner
            v-if="isSubmittingDecline"
            label="Processing"
            small
          />

          <div v-else>
            <feather-icon
              icon="XCircleIcon"
              size="16"
            />
          </div>
        </b-button>
      </b-col>

      <b-col
        class="d-flex justify-content-center"
        sm="5"
      >
        <b-button
          size="sm"
          variant="success"
          @click="handleAcceptClick"
        >
          <b-spinner
            v-if="isSubmittingAccept"
            label="Processing"
            small
          />

          <div v-else>
            <feather-icon
              icon="CheckCircleIcon"
              size="16"
            />
          </div>
        </b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BRow, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BSpinner,
  },
  props: {
    friend: {
      type: Object,
      default: null,
    },
    friendshipId: {
      type: Number,
      required: true,

    },
  },
  data() {
    return {
      currentTeam: null,
      isSubmittingDecline: false,
      isSubmittingAccept: false,

    }
  },
  methods: {
    handleViewClick() {
      this.$router.push({
        name: 'player-profile',
        params: { id: this.friend.user.id },
      })
    },
    async handleDeclineClick() {
      this.isSubmittingDecline = true

      const { success } = await this.$api.player.saveFriendRequest(this.friendshipId, false)

      this.isSubmittingDecline = false

      if (success) {
        await this.$store.dispatch('user/fetchMe')
      }
    },

    async handleAcceptClick() {
      this.isSubmittingAccept = true

      const { success } = await this.$api.player.saveFriendRequest(this.friendshipId, true)

      this.isSubmittingAccept = false

      if (success) {
        await this.$store.dispatch('user/fetchMe')
      }
    },
  },
}
</script>
