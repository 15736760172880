var render = function () {
  var _vm$friend, _vm$friend$user, _vm$friend2, _vm$friend2$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "align-items-center p-2 w-100 m-0 h-100 ",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "text-center"
  }, [_c('b-col', [_c('b-avatar', {
    attrs: {
      "src": (_vm$friend = _vm.friend) === null || _vm$friend === void 0 ? void 0 : (_vm$friend$user = _vm$friend.user) === null || _vm$friend$user === void 0 ? void 0 : _vm$friend$user.avatar_image,
      "size": "82"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-1  justify-content-between"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s((_vm$friend2 = _vm.friend) === null || _vm$friend2 === void 0 ? void 0 : (_vm$friend2$user = _vm$friend2.user) === null || _vm$friend2$user === void 0 ? void 0 : _vm$friend2$user.name))]), _vm._v(" want's to become your friend? ")])], 1), _c('b-row', {
    staticClass: "mt-1 mb-1  justify-content-between"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-center mt-1",
    attrs: {
      "sm": "12"
    }
  }, [_c('b-button', {
    staticClass: "flex-grow-1",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleViewClick
    }
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "ViewIcon",
      "size": "16"
    }
  }), _c('span', [_vm._v("profile")])], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-1 justify-content-between"
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "danger"
    },
    on: {
      "click": _vm.handleDeclineClick
    }
  }, [_vm.isSubmittingDecline ? _c('b-spinner', {
    attrs: {
      "label": "Processing",
      "small": ""
    }
  }) : _c('div', [_c('feather-icon', {
    attrs: {
      "icon": "XCircleIcon",
      "size": "16"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "success"
    },
    on: {
      "click": _vm.handleAcceptClick
    }
  }, [_vm.isSubmittingAccept ? _c('b-spinner', {
    attrs: {
      "label": "Processing",
      "small": ""
    }
  }) : _c('div', [_c('feather-icon', {
    attrs: {
      "icon": "CheckCircleIcon",
      "size": "16"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }