<template>
  <b-card
    class="align-items-center p-2 w-100 m-0 h-100 "
    no-body
  >
    <b-row class="text-center">
      <b-col>
        <b-avatar
          :src="team.avatar_image"
          size="82"
        />
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <h5>{{ team.name }}</h5>
      </b-col>
    </b-row>

    <!-- Leave team -->
    <b-row
      v-if="!isTeamOwner(team)"
      class="mt-1"
    >
      <b-col
        class="d-flex justify-content-center"
        sm="12"
      >
        <b-button
          class="flex-grow-1"
          size="sm"
          variant="danger"
          @click="leaveTeam"
        >
          <feather-icon
            icon="LogOutIcon"
            size="12"
          />
          <span class="mr-25 align-middle">Leave</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- Edit team -->
    <b-row
      v-if="isTeamOwner(team)"
      class="mt-1 mb-1"
    >
      <b-col
        class="d-flex justify-content-center"
        sm="12"
      >
        <b-button
          size="sm"
          variant="primary"
          class="flex-grow-1"
          @click="$router.push({ name: 'team-edit', params: { id: team.id } })"
        >
          <feather-icon
            icon="EditIcon"
            size="12"
            class="mr-1"
          />
          <span class="mr-25 align-middle">Edit</span>
        </b-button>
      </b-col>

      <b-col
        class="d-flex justify-content-center mt-1"
        sm="12"
      >
        <b-button
          size="sm"
          variant="primary"
          class="flex-grow-1"
          @click="handleViewClick"
        >
          <feather-icon
            icon="EyeIcon"
            size="12"
            class="mr-1"
          />
          <span class="mr-25 align-middle">Visit</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAvatar, BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BButton,
  },
  props: {
    team: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentTeam: null,
    }
  },
  methods: {
    isTeamOwner(team) {
      const me = this.$store.getters['user/getMe']
      if (!me) {
        return false
      }

      return team.owner_id === me.id
    },
    handleViewClick() {
      this.$router.push({
        name: 'team-profile',
        params: { id: this.team.id },
      })
    },
    leaveTeam() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Leave',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const { success } = await this.$api.team.leaveTeam(this.team.id)

          if (success === true) {
            await this.$store.dispatch('user/fetchMe')
          }
        }
      })
    },
  },
}
</script>
